<template>
  <div id="processWorksheetForm" class="form">
    <div class="d-flex justify-end">
      <v-btn @click="toggleStatus()" text>{{processWorksheet.status}}</v-btn>
    </div>
    <v-form v-if="loaded" ref="form" v-model="valid" lazy-validation>
      <div class="d-flex flex-column justify-space-between">
        <v-select
          class="mx-2"
          v-if="worksheetTypeFields.hasProcessType"
          v-model="processWorksheet.processTypeId"
          :items="processTypes"
          label="Process Type"
          :multiple="false"
          item-text="name"
          item-value="id"
          :chips="false"
          deletable-chips
        ></v-select>
        <v-select
          class="mx-2"
          v-if="worksheetTypeFields.hasLocation"
          v-model="processWorksheet.locationId"
          :items="locations"
          label="Location"
          :multiple="false"
          item-text="name"
          item-value="id"
          :chips="false"
          deletable-chips
          @change="generated = false"
          clearable
        ></v-select>
        <product-select
          required
          v-if="worksheetTypeFields.hasProduct"
          @input="generated = false"
          class="mx-2 mb-6"
          v-model="processWorksheet.productId"
        />
        <v-text-field
          class="mx-2"
          v-if="worksheetTypeFields.hasDeductibleWeight"
          v-model="processWorksheet.deductibleWeight"
          label="Deductible Weight"
          @change="deductibleWeightChanged"
          :required="false"
        />
        <v-text-field
          class="mx-2"
          v-if="worksheetTypeFields.hasTargetWeight"
          v-model="processWorksheet.targetWeight"
          label="Target Weight"
          :required="false"
        />
        <v-text-field
          class="mx-2"
          v-if="worksheetTypeFields.hasDocumentNumber"
          v-model="processWorksheet.documentNumber"
          label="Document Number"
          :required="false"
        />
        <date-picker label="Process Time" showTime :defaultNowOnNull="false" :show-hint="false" :show-icon="false" v-model="processWorksheet.processTime"/>

        <v-textarea v-if="worksheetTypeFields.hasMemo" label="Memo" v-model="processWorksheet.memo" outlined />
        <v-textarea label="Worksheet Notes" v-model="processWorksheet.notes" outlined />

        <div class="mt-4 d-flex justify-center">
          <v-btn @click="handleSubmit" color="primary">
            {{ id ? "Update" : "Submit" }}
          </v-btn>
          <span></span>
        </div>
      </div>
      <v-divider class="my-6"/>
      <v-sheet :elevation="2" class="pa-4">
        <v-subheader>Entries</v-subheader>
        <div
          class="d-flex justify-space-between"
          v-for="(entry, index) in processWorksheet.entries"
          :key="index"
        >
          <div class="my-auto mr-2">{{ index + 1 }}.</div>
          <v-text-field
            class="mx-2"
            label="Gross Weight"
            @change="weightChanged(entry)"
            v-model="entry.grossWeight"
            :disabled="entry.inventoryUnitId"
          ></v-text-field>
          <v-text-field
            class="mx-2"
            label="Net Weight"
            disabled
            v-model="entry.netWeight"
          ></v-text-field>
          <div class="mx-2 my-auto nowrap">
            {{ entry.updateTime | formatDateTime }}
          </div>
          <div class="mx-2 my-auto">
            <v-icon @click="deleteEntry(index)">mdi-delete-outline</v-icon>
          </div>
        </div>
        <div class="text-h6 text-center">
          Total Net Weight: {{totalWeight(processWorksheet.entries) | formatNumber}}
        </div>
        <v-btn :disabled="processWorksheet.status == 'SUBMITTED'" @click="addEntry" class="ml-7">Add Measurement</v-btn>
        <div v-if="processWorksheet.status == 'SUBMITTED'" class="text-caption ml-7 mt-2">Submitted worksheet is immutable</div>
      </v-sheet>

    </v-form>
        <confirmation-dialog ref="confirm" />

  </div>
</template>

<script>
import { getProcessWorksheet } from "@/services/processWorksheet";
const ProductSelect = () => import("@/components/product/ProductSelect.vue");
import { mapActions, mapGetters } from "vuex";

export default {
  props: ["id"],
  data() {
    return {
      loaded: false,
      valid: false,
      processWorksheet: {
        worksheetTypeId: null,
        worksheetType: null,
        staffId: null,
        staffName: null,
        supplierId: null,
        supplierName: null,
        vehicleId: null,
        customerId: null,
        customerName: null,
        documentNumber: null,
        processTypeId: null,
        processInputMethod: null,
        processDirection: null,
        requirePhoto: null,
        productId: null,
        locationId: null,
        deductibleWeight: 0,
        targetWeight: 0,
        memo: null,
        notes: null,
        status: "",
        processTime: null,
        createTime: "",
        entries: [{ grossWeight: 0, netWeight: 0, time: new Date() }],
      },
    };
  },
  computed: {
    ...mapGetters("auth", ["currentUser"]),
    ...mapGetters("processes", ["processTypes"]),
    ...mapGetters("locations", ["locations"]),
        ...mapGetters("worksheetTypeDefinition", ["worksheetTypeDefinitions"]),

    worksheetTypeFields() {
      if (
        this.worksheetTypeDefinitions &&
        this.worksheetTypeDefinitions.length > 0 &&
        this.processWorksheet.worksheetTypeId
      ) {
        return this.worksheetTypeDefinitions.find(
          (t) => t.id == this.processWorksheet.worksheetTypeId
        );
      } else {
        return {};
      }
    },
  },
  created() {
    this.setInstance();
  },
  methods: {
    ...mapActions("processWorksheet", [
      "createProcessWorksheet",
      "updateProcessWorksheet",
    ]),
    ...mapActions("messages", [
      "addMessage",
      "addErrorMessage",
      "addSuccessMessage",
    ]),
    ...mapActions("processes", ["loadProcessTypes"]),
    ...mapActions("locations", ["loadLocations"]),
    ...mapActions("worksheetTypeDefinition", ["fetchWorksheetTypeDefinitions"]),

    async handleSubmit() {

      if(this.processWorksheet.productId == null){
        this.addErrorMessage("Product field is required");
        return;
      }

      if (this.id) {
        let processWorksheet = this.processWorksheet;
        delete processWorksheet.relations;

        this.updateProcessWorksheet(processWorksheet)
          .then((response) => {
            console.log(response);
            this.addSuccessMessage("ProcessWorksheet was updated successfully");
            this.goBack();
          })
          .catch(() => {
            this.addErrorMessage(
              "Error updating ProcessWorksheet, please try again later"
            );
          });
      } else {
        let processWorksheet = this.processWorksheet;
        delete processWorksheet.relations;

        this.createProcessWorksheet(processWorksheet)
          .then((response) => {
            console.log(response);
            this.addSuccessMessage("ProcessWorksheet was created successfully");
            this.goBack();
          })
          .catch(() => {
            this.addErrorMessage(
              "Error creating ProcessWorksheet, please try again later"
            );
          });
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    setInstance() {
      if (this.id) {
        this.processWorksheet.id = this.id;
        getProcessWorksheet(this.id).then((response) => {
          let instance = response.data;
          for (let property in instance) {
            if (
              Object.prototype.hasOwnProperty.call(instance, property) &&
              Object.prototype.hasOwnProperty.call(
                this.processWorksheet,
                property
              )
            ) {
              this.processWorksheet[property] = instance[property];
              this.fetchWorksheetTypeDefinitions()
            }
          }

          this.loaded = true;
        });
      } else {
        this.processWorksheet.staffId = this.currentUser.uId;
        this.loaded = true;
      }

      this.loadProcessTypes();
      this.loadLocations();
    },
    addEntry() {
      const newEntry = {
        processWorksheetId: this.processWorksheet.id,
        grossWeight: 0,
        netWeight: 0,
        notes: null,
        time: new Date(),
      };

      if(!this.processWorksheet.entries){
        this.processWorksheet.entries = [];
      }

      this.processWorksheet.entries.push(newEntry);
    },
    saveWorksheetEntry(worksheet, entry) {
      const grossWeight = entry.grossWeight;
      const netWeight = grossWeight - worksheet.deductibleWeight;
      if(netWeight <= 0){
        this.addErrorMessage(`Gross weight has to be greater than ${worksheet.deductibleWeight} ${this.weightUnit}`)
        return;
      }

      let data = Object.assign({}, entry);
      data.grossWeight = grossWeight;
      data.netWeight = netWeight;
      data.staffId = this.currentUser.uId;


      if (entry.id) {
        this.$axios
          .put(`/processWorksheetInput/${entry.id}`, data)
          .then((resp) => {
            this.currentEntry = { grossWeight: 0 };
            const updatedEntry = resp.data;
            updatedEntry.createTime = Date.now();
            updatedEntry.updateTime = Date.now();
            const matchIndex = this.processWorksheet.entries.findIndex(
              (e) => e.id == updatedEntry.id
            );
            this.processWorksheet.entries[matchIndex] = updatedEntry;

            this.addSuccessMessage("Entry updated successfully");
          });
      } else {
        this.$axios.post("/processWorksheetInput", data).then((resp) => {
          this.currentEntry = { grossWeight: 0 };
          const saved = resp.data;
          saved.createTime = Date.now();
          saved.updateTime = Date.now();

          const newEntryIndex = this.processWorksheet.entries.findIndex(e => !e.id);
          this.processWorksheet.entries[newEntryIndex] = saved;

          this.addSuccessMessage("Entry added successfully");
        });
      }
    },
    weightChanged(entry) {
      const netWeight =
        entry.grossWeight - this.processWorksheet.deductibleWeight;
      this.$set(entry, "netWeight", netWeight);
      this.saveWorksheetEntry(this.processWorksheet, entry);
    },
    deductibleWeightChanged() {
      this.processWorksheet.entries.forEach((entry) => {
        this.weightChanged(entry);
      });
    },
    async deleteEntry(index) {
      const entry = this.processWorksheet.entries[index];
      await this.$axios
          .delete(`/processWorksheetInput/${entry.id}`)
          .then(() => {
            this.processWorksheet.entries.splice(index, 1);
            this.addSuccessMessage("Entry deleted successfully");
          })
          .catch((error) => {
            console.error(error);
            this.addErrorMessage("Error deleting entry");
          })
    },
    async toggleStatus(){
      const originalStatus = this.processWorksheet.status;
      
      let toggleStatus = 'PENDING';

      switch(originalStatus){
        case 'SUBMITTED':
          toggleStatus = 'PENDING';
          break;
        case 'PENDING':
        case 'USED':
          toggleStatus = 'SUBMITTED';
          break;
      }
      
      let confirm = await this.$refs.confirm.open(
        "Confirm Change Status",
        "Please confirm you would like to change status to " + toggleStatus
      );
      
      if (confirm) {
        if(originalStatus == 'USED'){
          this.$axios.put(`/processWorksheet/unmark-used/${this.id}`)
          .then(() => {
            this.processWorksheet.status = toggleStatus;
            this.addSuccessMessage("Worksheet status updated successfully");
          })
          .catch((error) => {
            console.log(error);
            this.processWorksheet.status = originalStatus;
            const message = error.data.message || "Error updating worksheet status";
            this.addErrorMessage(message);
          });
        }
        else{ 
          this.processWorksheet.status = toggleStatus;
          this.updateProcessWorksheet(this.processWorksheet)
            .then(() => {
              this.addSuccessMessage("Worksheet status updated successfully");
            })
            .catch((error) => {
              console.log(error);
              this.processWorksheet.status = originalStatus;
              const message = error.data.message || "Error updating worksheet status";
              this.addErrorMessage(message);
            });
        }
      }
    },
    totalWeight(entries){
      if(entries && entries.length > 0){
        return entries.reduce((sum, a) => { return sum += a.netWeight; }, 0);
      }
      else{ return 0; }
    },
  },
  components: { ProductSelect },
};
</script>
<style scoped>
.nowrap {
  white-space: nowrap;
}
</style>
